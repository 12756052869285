<template>
    <v-data-table :headers="headers" :items="queryPreviewData.tableData" :sort-desc="[true, true]" hide-default-footer class="text-truncate">
        <template v-slot:top>
            <v-banner single-line class="mb-5">
                <v-avatar slot="icon" color="white" size="60">
                    <v-icon x-large icon="info" color="info">info</v-icon>
                </v-avatar>
                <span class="info--text subtitle-2 font-weight-medium">This is a preview only, click on "SAVE FULL RESULTS" to get the full data.</span>
                <template v-slot:actions>
                    <v-btn color="primary" class="font-weight-bold ml-1 caption" :loading="loading" @click="saveFullResults">
                        <v-icon class="mr-1" small>save</v-icon>
                        Save Full Results
                    </v-btn>
                    <GetTableData :sqlText="queryPreviewData.sql" />
                    <v-dialog v-model="sqlDialog" max-width="500">
                        <template v-slot:activator="{ on }">
                            <v-btn text v-on="on">
                                <div class="d-flex align-center">
                                    <v-icon class="mr-1" small color="secondary" dark>mdi-eye-outline</v-icon>
                                    <span class="font-weight-bold caption secondary--text">View Query</span>
                                </div>
                            </v-btn>
                        </template>
                        <v-card>
                            <v-card-title>
                                <div class="secondary--text">
                                    <v-icon class="mr-1">mdi-script-text-outline</v-icon>
                                    SQL Text
                                </div>
                            </v-card-title>
                            <v-divider class="mb-1"></v-divider>
                            <v-card-text>
                                <CodeHighlight language="sql" :code="queryPreviewData.sql" />
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="secondary" text @click="sqlDialog = false">Close</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </template>
            </v-banner>
        </template>
        <template v-slot:no-data>
            <v-alert text :value="true" color="info" icon="info" class="mt-3">No results for this query.</v-alert>
        </template>
    </v-data-table>
</template>
<script>
import { mapState } from 'vuex'
import { queryData } from '@/mixins/query'
const GetTableData = () => import('../components/TheSnapshotTableGetData')
const CodeHighlight = () => import('@/components/CodeHighlight')

export default {
    name: 'TablePreview',
    mixins: [queryData],
    components: {
        GetTableData,
        CodeHighlight
    },
    data() {
        return {
            sqlDialog: false,
            loading: false,
            sqlQueryFromQueryBuilder: null
        }
    },
    computed: {
        ...mapState('snapshotStore', ['queryPreviewData']),
        headers() {
            const headers = []
            this.queryPreviewData.headers.forEach(row => {
                headers.push({ text: row, align: 'left', value: row })
            })
            return headers
        }
    },
    methods: {
        saveFullResults: function () {
            this.loading = true
            if (this.queryPreviewData.sql) {
                const postBody = {
                    command: this.queryPreviewData.sql,
                    mode: this.queryModeOptions.FULL_WITH_SAVE_MODE,
                    snid: this.$route.params.snid
                }
                this.$store.dispatch('showSnackBar', {
                    snackBarText: 'Saving full results..',
                    snackBarTimeout: 10000,
                    snackBarIcon: 'info'
                })
                this.$axios
                    .post('/queries', postBody)
                    .then(response => {
                        this.$emit('queryFilesSaveSuccess', { value: true })
                    })
                    .catch(() => {
                        this.$store.dispatch('showSnackBar', {
                            snackBarText: 'Failed to submit the query, please retry later..',
                            snackBarTimeout: 10000,
                            snackBarIcon: 'error'
                        })
                    })
                    .finally(() => {
                        this.loading = false
                    })
            }
        }
    }
}
</script>
